import React from "react"
import { Link, graphql } from "gatsby"
import Img, { FluidObject } from "gatsby-image"
import Layout from "../components/layout"
import ReactMarkdown from "react-markdown"
import CodeBlock from "../components/CodeBlock"

interface Props {
  data: Data
  pageContext: PageContextData
}

interface PageContextData {
  id: string
  prevPost: {
    title: string
    path: string
  }
  nextPost: {
    title: string
    path: string
  }
}

interface Data {
  strapiArticle: {
    title: string
    content: string
    strapiId: string
    published_at: string
    image: {
      childImageSharp: {
        fluid: FluidObject
      }
    }
    slug: string
    category: {
      name: string
    }
    author: {
      name: string
    }
    excerpt: string
  }
}

export const query = graphql`
  query ArticleQuery($id: String!) {
    strapiArticle(strapiId: { eq: $id }) {
      title
      content
      published_at
      image {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      slug
      category {
        name
      }
      author {
        name
      }
      excerpt
    }
  }
`

const Blog: React.FC<Props> = ({ data, pageContext }) => {
  const article = data.strapiArticle
  return (
    <Layout>
      <div className="mb-4">
        <Link
          className="font-mont font-bold transition ease-in hover:text-blackish"
          to="/"
        >
          Home
        </Link>{" "}
        &#x27B2;{" "}
        <Link
          className="font-mont font-bold transition ease-in hover:text-blackish"
          to="/blog"
        >
          Blog
        </Link>{" "}
        &#x27B2;{" "}
        <span className="font-mont font-bold ">{article.category.name}</span>
      </div>

      <div className="bg-white h-fit text-blackish py-8 px-4 sm:p-8 rounded-md mb-10 flex flex-col">
        <h1 className=" text-3xl font-monda sm:text-5xl font-bold mb-2">
          {data.strapiArticle.title}
        </h1>
        <p className="font-bold font-monda">
          {article.excerpt.slice(0, 200) + "..."}
        </p>
        <div className="flex justify-between items-center">
          <p className="font-mont text-sm">
            By <strong>{article.author.name}</strong>
          </p>
          <p className="font-mont text-sm">{article.published_at}</p>
        </div>

        <Img
          fluid={article.image.childImageSharp.fluid}
          alt={article.title}
          className="max-h-1/2"
        />

        <div className="py-12 font-mont text-lg float-right w-full font-medium">
          <ReactMarkdown
            source={article.content}
            renderers={{ code: CodeBlock }}
          />
        </div>

        <hr></hr>

        <div className="flex my-12 bg-black grid grid-cols-2 font-mont rounded py-2 px-4">
          {pageContext.prevPost.title !== "" && (
            <div className="flex flex-col col-start-1 text-white">
              <span className="text-sm font-monda mb-2">
                &#x219e; Previous Article
              </span>
              <Link
                to={pageContext.prevPost.path}
                className="my-0 w-fit font-mont"
              >
                {pageContext.prevPost.title}
              </Link>
            </div>
          )}

          {pageContext.nextPost.title !== "" && (
            <div className="flex flex-col col-start-2  text-white">
              <span className="text-sm font-monda text-right mb-2">
                Next Article &#x21a0;
              </span>
              <Link
                to={pageContext.nextPost.path}
                className="my-0 text-right w-fit font-mont"
              >
                {pageContext.nextPost.title}
              </Link>
            </div>
          )}
        </div>
      </div>
    </Layout>
  )
}

export default Blog
